import { useEffect } from 'react'
import styled from 'styled-components'
import { Close } from '../../Icons'

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: ${({ minimize }) => (minimize ? '0' : '140px')};
	z-index: 1000;
	overflow: hidden;
`

const ModalContainer = styled.div`
	position: relative;
	background-color: ${({ minimize }) =>
		minimize ? 'transparent' : 'rgb(45, 45, 45)'};
	border-radius: ${({ minimize }) => (minimize ? '0' : '8px')};
	padding: ${({ minimize }) => (minimize ? '0' : '20px')};
	max-width: ${({ minimize }) => (minimize ? 'unset' : '90vw')};
	max-height: ${({ minimize }) => (minimize ? '100vh' : 'calc(90vh - 140px)')};
	overflow-y: ${({ minimize }) => (minimize ? 'hidden' : 'auto')};
	box-shadow: ${({ minimize }) =>
		minimize ? 'none' : '0 2px 10px rgba(0, 0, 0, 0.2)'};
	z-index: 1001;
	display: flex;
	flex-direction: column;
	animation: slideIn 0.3s ease-in-out;

	@media (max-width: 768px) {
		max-width: ${({ minimize }) => (minimize ? 'unset' : '95vw')};
		max-height: ${({ minimize }) => (minimize ? '100vh' : '80vh')};
		padding: ${({ minimize }) => (minimize ? '0' : '15px')};
	}

	@keyframes slideIn {
		from {
			transform: translateY(-20px);
			opacity: 0;
		}
		to {
			transform: translateY(0);
			opacity: 1;
		}
	}
`

const CloseButton = styled.button`
	background-color: transparent;
	border: none;
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1002;
	display: ${({ minimize }) => (minimize ? 'none' : 'block')};

	svg {
		width: 30px;
		height: 30px;
		fill: #fff;
	}
`

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	justify-content: center;
	align-items: center;
	padding: ${({ minimize }) => (minimize ? '0' : '25px')};
	width: 100%;
	height: 100%;
`

const ModalHeader = styled.h2`
	margin: 0;
	font-size: 2rem;
	text-align: center;
	color: #ffffff;
	display: ${({ minimize }) => (minimize ? 'none' : 'block')};
`

const ModalBody = styled.div`
	width: 100%;
	text-align: left;
	display: ${({ minimize }) => (minimize ? 'none' : 'block')};
`

const ImageContainer = styled.div`
	display: ${({ minimize }) =>
		minimize ? 'flex' : 'none'}; // Используем flex для центрирования
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 20px; // Отступы от краев экрана
	box-sizing: border-box; // Учитываем padding в размерах

	img {
		max-height: calc(100vh - 40px); // Высота с учетом отступов
		max-width: 100%; // Ширина ограничена шириной экрана
		object-fit: contain; // Изображение вписывается полностью
	}
`

const Modal = ({ isOpen, onClose, title, children, minimize }) => {
	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden'
		}
		return () => {
			document.body.style.overflow = ''
		}
	}, [isOpen])

	if (!isOpen) return null

	return (
		<Overlay minimize={minimize} onClick={onClose}>
			<ModalContainer minimize={minimize} onClick={e => e.stopPropagation()}>
				<CloseButton minimize={minimize} onClick={onClose}>
					<Close />
				</CloseButton>
				<ModalContent minimize={minimize}>
					<ModalHeader minimize={minimize}>{title}</ModalHeader>
					<ModalBody minimize={minimize}>{children}</ModalBody>
					{minimize && (
						<ImageContainer minimize={minimize}>
							{children} {/* Отображаем переданный контент (изображение) */}
						</ImageContainer>
					)}
				</ModalContent>
			</ModalContainer>
		</Overlay>
	)
}

export default Modal
