import classNames from 'classnames'
import React, { useState } from 'react'
import { NavMenu } from '..'
import styles from './Header.module.scss'
import Logo from './logo'

const Header = ({ openModal }) => {
	const [showHeader, setShowHeader] = useState(true)
	const [scrollPosition, setScrollPosition] = useState(0)
	// const handleScroll = () => {
	// 	const currentScroll = window.scrollY || document.documentElement.scrollTop

	// 	if (currentScroll <= 0 || currentScroll < scrollPosition) {
	// 		setShowHeader(true)
	// 	} else {

	// 		setShowHeader(false)
	// 	}

	// 	setScrollPosition(currentScroll)
	// }

	// useEffect(() => {
	// 	window.addEventListener('scroll', handleScroll)

	// 	return () => {
	// 		window.removeEventListener('scroll', handleScroll)
	// 	}
	// }, [scrollPosition])

	return (
		<div
			className={classNames(
				styles.header,
				showHeader ? styles['header--visible'] : styles['header--hidden']
			)}
		>
			<Logo />
			<NavMenu openModalWithData={openModal} />
		</div>
	)
}

export default Header
