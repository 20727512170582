import React from 'react'
import { TASKS } from '../../dummy'
import styles from './Tasks.module.scss'

const Task = ({ title, desc, foolDesk, url, openModal }) => {
	const handleModal = () => {
		openModal(
			title,
			<div className={styles.wrap}>
				{url && (
					<div className={styles.imgWrap}>
						<img src={url} alt='' />
					</div>
				)}
				{foolDesk && (
					<div className={styles.deskWrap}>
						<p>{foolDesk}</p>
					</div>
				)}
			</div>,
			true
		)
	}

	return (
		<div className={styles.taskBlock}>
			<div className={styles.taskTitle}>{title}</div>
			<div className={styles.dummyDesc}>{desc}</div>
		</div>
	)
}

const Tasks = ({ openModal }) => {
	return (
		<div className={styles.tasks}>
			{TASKS.map((task, index) => (
				<Task
					key={index}
					title={task.title}
					desc={task.desc}
					foolDesk={task.foolDesk}
					url={task.url}
					openModal={openModal}
				/>
			))}
		</div>
	)
}

export default Tasks
