import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import ReactPlayer from 'react-player'
import { useSwipeable } from 'react-swipeable'
import DemoFilter from '../../components/DemoFilter/DemoFilter'
import styles from './Demo.module.scss'
import { PoligonSvg } from './images'

const generateAssetPaths = id => ({
	forward: `Demo/${id}.mp4`,
	backward: `Demo/${id}_revers.mp4`,
	image: `Demo/${id}.webp`,
	svg: `Demo/svg/${id}.svg`, // Если SVG недоступен, можно динамически исключить его позже.
})

const introPath = 'Demo/intro.mp4'

const videoAssets = [
	generateAssetPaths(1),
	generateAssetPaths(2),
	generateAssetPaths(3),
	generateAssetPaths(4),
]

const Demo = ({ openModal }) => {
	const [currentVideo, setCurrentVideo] = useState({
		index: 0,
		direction: 'forward',
	})
	const [previousVideo, setPreviousVideo] = useState({ index: 0 })
	const [showImage, setShowImage] = useState(true) // Отображение картинки во время смены
	const [playing, setPlaying] = useState(false) // Состояние воспроизведения
	const [isFirstPlay, setIsFirstPlay] = useState(true)
	const [isIntroPlayed, setIsIntroPlayed] = useState(false)
	const [clickControl, setClickControl] = useState(false)

	const demoRef = useRef(null)
	const [isLoading, setIsLoading] = useState(true)
	const [isVisible, setIsVisible] = useState(false)

	const [svgCache, setSvgCache] = useState({}) // Кэш всех SVG
	const [svgContent, setSvgContent] = useState('') // Текущий контент SVG

	const [activeTab, setActiveTab] = useState('overview')
	const [rooms, setRooms] = useState([])
	const [available, setAvailable] = useState(null)

	const handle = useFullScreenHandle()
	const [isFullScreen, setIsFullScreen] = useState(false)

	const [resetFlag, setResetFlag] = useState(false)
	const [rangeValues, setRangeValues] = useState({
		costRange: [5, 10],
		surfaceRange: [60, 240],
		floorRange: [1, 10],
	})

	const handleResetFilter = () => {
		setRooms([])
		setRangeValues({
			costRange: [5, 10],
			surfaceRange: [60, 240],
			floorRange: [1, 10],
		})
	}

	// Универсальная функция для обновления любого диапазона
	const handleRangeChange = (key, values) => {
		setRangeValues(prev => ({
			...prev,
			[key]: values, // Изменяем только нужный диапазон
		}))
	}

	const handleFullScreenChange = () => {
		isFullScreen ? handle.exit() : handle.enter()
		setIsFullScreen(!isFullScreen)
	}

	// const handleVideoEnd = () => {
	// 	if (!isIntroPlayed) {
	// 		setPlaying(false)
	// 		setIsIntroPlayed(true)
	// 	} else {
	// 		setPreviousIndex(currentIndex)
	// 		setPlaying(false)
	// 	}
	// }
	const handleVideoEnd = () => {
		setPlaying(false)
		setPreviousVideo({ ...currentVideo })
		setShowImage(true)
		setClickControl(false)
		if (!isIntroPlayed) setIsIntroPlayed(true)
	}

	const handleVideoReady = () => {
		if (isIntroPlayed) {
			if (!isFirstPlay) {
				setShowImage(false)
				setPlaying(true)
			}

			setIsFirstPlay(false)
		}
	}

	// Показываем картинку при смене видео
	const handlePrev = () => {
		if (playing) return
		if (!clickControl) {
			setTimeout(() => {
				setCurrentVideo(prev => ({
					...prev,
					index: (prev.index - 1 + videoAssets.length) % videoAssets.length,
					direction: 'backward',
				}))
			}, [250])
		}
		setClickControl(true)
	}

	// Обработка нажатия на Next — меняем видео и запускаем воспроизведение
	const handleNext = () => {
		if (playing) return
		if (!clickControl) {
			setTimeout(() => {
				setCurrentVideo(prev => ({
					...prev,
					index: (prev.index + 1) % videoAssets.length,
					direction: 'forward',
				}))
			}, [250])
		}
		setClickControl(true)
	}

	const handlers = useSwipeable({
		onSwipedLeft: !playing ? handleNext : undefined,
		onSwipedRight: !playing ? handlePrev : undefined,
		preventDefaultTouchmoveEvent: false,
		trackMouse: true,
	})

	const preloadAllAssets = async () => {
		const videoAndImagePromises = videoAssets.map(video => {
			return Promise.all([
				loadMedia(video.forward),
				loadMedia(video.backward),
				loadImage(video.image),
			])
		})
		const svgPromises = videoAssets.map(async asset => {
			try {
				const response = await fetch(asset.svg)
				const text = await response.text()
				return { [asset.svg]: text }
			} catch (error) {
				console.error(`Error loading SVG: ${asset.svg}`, error)
				return { [asset.svg]: null }
			}
		})

		const svgResults = await Promise.all(svgPromises)
		const svgCache = svgResults.reduce(
			(cache, svgData) => ({ ...cache, ...svgData }),
			{}
		)

		await Promise.all(videoAndImagePromises)

		setSvgCache(svgCache)
	}

	const preloadIntro = async () => {
		try {
			await loadMedia(introPath) // Загружаем только интро
			console.log('Intro loaded')
		} catch (error) {
			console.error('Error loading intro', error)
		}
	}

	const loadMedia = src => {
		return new Promise(resolve => {
			const media = document.createElement('video')
			media.src = src
			media.preload = 'auto'
			media.onloadeddata = resolve
		})
	}

	const loadImage = src => {
		return new Promise(resolve => {
			const img = new Image()
			img.src = src
			img.onload = resolve
		})
	}

	useEffect(() => {
		preloadIntro().then(() => {
			preloadAllAssets()
		})
	}, [])

	useEffect(() => {
		console.log(isIntroPlayed)
	}, [isIntroPlayed])

	useEffect(() => {
		if (isVisible) {
			setIsLoading(false)
			!isIntroPlayed && setPlaying(true)
		}
	}, [isVisible])

	useEffect(() => {
		setSvgContent(svgCache[videoAssets[previousVideo.index]?.svg] || '')
	}, [previousVideo.index, svgCache])

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsVisible(true)

					// Если видно 1/3 блока, выполняем доскролл
					if (entry.intersectionRatio >= 0.33) {
						entry.target.scrollIntoView({ behavior: 'smooth', block: 'center' })
					}

					// Отключаем observer после первого срабатывания
					observer.disconnect()
				}
			},
			{
				threshold: [0.33, 0.6], // Срабатывает при видимости 1/3 и 60%
			}
		)

		if (demoRef.current) {
			observer.observe(demoRef.current)
		}

		return () => {
			if (demoRef.current) {
				observer.unobserve(demoRef.current)
			}
		}
	}, [])

	return (
		<div ref={demoRef} className={styles.demo}>
			<div className={styles.wrapTitle}>
				<h2>пример интерактивной демонстрации</h2>
				<p>Здесь вы можете ознакомиться с функционалом.</p>
			</div>

			{isLoading ? (
				<div ref={demoRef} className={styles.wrapLoader}>
					<progress className='progress w-56'></progress>
				</div>
			) : (
				<FullScreen
					handle={handle}
					className={classNames(
						styles['video-wrapper'],
						isFullScreen && styles.fullScreen
					)}
				>
					{/* Видео всегда на экране */}
					<ReactPlayer
						url={
							!isIntroPlayed
								? introPath
								: videoAssets[currentVideo.index][currentVideo.direction]
						}
						playing={playing}
						controls={false}
						width='100%'
						height='100%'
						preload='auto'
						muted={true}
						onEnded={handleVideoEnd}
						onReady={handleVideoReady}
						config={{
							file: {
								attributes: {
									poster: !isIntroPlayed
										? ''
										: videoAssets[currentVideo.index].image,
									preload: 'auto',
								},
							},
						}}
					/>
					{/* Отображение финального изображения и других элементов только после интро */}
					{isIntroPlayed && (
						<>
							<img
								src={videoAssets[previousVideo.index].image}
								alt='Final frame'
								className={classNames(
									styles['final-image'],
									showImage ? styles.visible : styles.hidden
								)}
							/>

							<div
								className={classNames(
									styles.poligonContainer,
									showImage && activeTab === 'filters' ? styles.visible : ''
								)}
								{...handlers}
							>
								<PoligonSvg
									activeTab={activeTab}
									openModal={openModal}
									svgId={videoAssets[previousVideo.index].svg}
									svgContent={svgContent}
									roomsFilter={rooms}
									{...rangeValues}
									availableFilter={available}
								/>
							</div>

							<div className={classNames(styles.controls, styles.hidden)}>
								<button
									disabled={playing}
									onClick={handlePrev}
									className={classNames(styles.btn, styles.back)}
								>
									<img src={'/Demo/svg/arrow.svg'} className={styles.left} />
								</button>
								<button
									disabled={playing}
									onClick={handleNext}
									className={classNames(styles.btn, styles.forward)}
								>
									<img src={'/Demo/svg/arrow.svg'} />
								</button>
							</div>
							<DemoFilter
								activeTab={activeTab}
								setActiveTab={setActiveTab}
								rooms={rooms}
								setRooms={setRooms}
								rangeValues={rangeValues}
								handleRangeChange={handleRangeChange}
								available={available}
								setAvailable={setAvailable}
								isFullScreen={isFullScreen}
								handleFullScreenChange={handleFullScreenChange}
								handleResetFilter={handleResetFilter}
								resetFlag={resetFlag}
							/>
						</>
					)}
				</FullScreen>
			)}
			<div className={styles.wrapSubTitle}>
				<p>
					Управлять интерактивным проектом легко на любом устройстве, интерфейс
					адаптирован также и для сенсорных экранов!<br></br>Откройте наш сайт
					со своего смартфона и управляйте демонстрацией привычными жестами.
				</p>
			</div>
			<div className={styles.wrapBtn}>
				<p>Оставьте заявку на сайте и мы свяжемся с Вами в течении дня.</p>
				<a href='#formBlock'>
					<button className={styles.btn}>Оставить заявку</button>
				</a>
			</div>
		</div>
	)
}

export default Demo
